<template>
  <div  >
       <b-navbar toggleable="lg" type="dark"  position="top"  class="bgtopbar"  >
        <b-navbar-brand href="#" v-on:click="goback()"> Back </b-navbar-brand>
    </b-navbar>
    <div class="d-flex justify-content-center" md="6" >
    <b-card border-variant="dark" header="ຂໍ້ມູນຂອງທ່ານບໍ່ຄົບຖ້ວນ" align="center" bg-variant="danger" text-variant="white"   class="text-center">
        <b-card-text>
          ຂໍ້ມູນຂອງທ່ານທີ່ປະກອບມາບໍ່ຄົບຖ້ວນຕາມເງື່ອນໄຂ. 
          <br> 
          ກະລຸນາກວດກາຂໍ້ມູນທ່ານໃຫ້ຖືກຕ້ອງແລ້ວປະກອບຂໍ້ມູນການຈອງບັດຄິວຄືນໃໝ່.

        </b-card-text>

      </b-card>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {

    }
  },
  methods: {
        goback() {
            this.$router.go(-1)
        }
  }
};
</script>